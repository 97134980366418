<template>
  <el-dialog
    title="添加预警规则"
    :visible.sync="dialogVisible"
    width="946px"
    :close-on-click-modal="false"
    @closed="clearForm"
  >
    <div class="row1">
      <div class="item">
        <div class="desc required">预警名称：</div>
        <el-input placeholder="请输入" v-model.trim="warn_name" maxlength="12" />
      </div>

      <div class="item">
        <div class="desc required">预警类型：</div>
        <el-select v-model="device_type" placeholder="请选择">
          <el-option
            v-for="item in [
              { label: '水箱', value: 0 },
              { label: '水泵', value: 1 },
              { label: '机组', value: 2 },
            ]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="row2" v-if="device_type !== ''">
      <div class="desc">预警内容：</div>
      <div class="item_box">
        <div
          :class="item.checked ? 'checked' : 'item'"
          v-for="item in filterFormList"
          :key="item.tpl"
          @click="item.checked = !item.checked"
        >
          {{ item.name }}
        </div>
      </div>
    </div>

    <div class="row3" v-if="isFormNotEmpty">
      <!-- 套两层娃 -->
      <!-- 沙雕语言, 我也不知道为什么要这样写 -->
      <!-- 用template循环,key写在template上提示要把key写在子元素上 -->
      <!-- key写在子元素上提示渲染的元素必须要key -->
      <!-- 都写上key尼玛提示多余,SB -->
      <div v-for="item in filterFormList" :key="item.tpl">
        <div v-if="item.checked">
          <div class="group" v-if="item.tpl === 0">
            <div class="tpl">
              <div class="font">当</div>
              <el-select class="ipt" v-model="item.channel_index" placeholder="水位">
                <el-option
                  v-for="item in level"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <div class="font">持续</div>
              <el-input
                class="ipt"
                placeholder="请输入"
                v-model="item.duration"
                maxlength="4"
              />
              <div class="font">min</div>
              <div class="font">低于</div>
              <el-input
                class="ipt"
                placeholder="请输入"
                v-model="item.min_value"
                maxlength="3"
              />
              <div class="font">%</div>
              <div class="font">或高于</div>
              <el-input
                class="ipt"
                placeholder="请输入"
                v-model="item.max_value"
                maxlength="3"
              />
              <div class="font">%</div>
            </div>
          </div>

          <div class="group" v-else-if="item.tpl === 1">
            <div class="tpl">
              <div class="font">当</div>
              <el-select class="ipt" v-model="item.channel_index" placeholder="水温">
                <el-option
                  v-for="item in temp"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <div class="font">持续</div>
              <el-input
                class="ipt"
                placeholder="请输入"
                v-model="item.duration"
                maxlength="4"
              />
              <div class="font">min</div>
              <div class="font">低于</div>
              <el-input
                class="ipt"
                placeholder="请输入"
                v-model="item.min_value"
                maxlength="3"
              />
              <div class="font">℃</div>
              <div class="font">或高于</div>
              <el-input
                class="ipt"
                placeholder="请输入"
                v-model="item.max_value"
                maxlength="3"
              />
              <div class="font">℃</div>
            </div>
          </div>

          <div class="group" v-else-if="item.tpl === 2">
            <div class="tpl">
              <div class="font">当供水泵</div>
              <el-select class="ipt" v-model="item.channel_index" placeholder="电流">
                <el-option
                  v-for="item in pump_cycle"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <div class="font">大于1A时</div>
              <el-select
                class="ipt"
                v-model="item.check_channel_index"
                placeholder="水温"
              >
                <el-option
                  v-for="item in temp"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>

              <div class="font">低于</div>
              <el-input
                class="ipt"
                placeholder="请输入"
                v-model="item.min_value"
                maxlength="3"
              />
              <div class="font">℃</div>
            </div>
          </div>

          <div class="group" v-else-if="item.tpl === 3">
            <div class="tpl">
              <div class="font">当供水泵</div>
              <el-select class="ipt" v-model="item.channel_index" placeholder="电流">
                <el-option
                  v-for="item in pump_cycle"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <div class="font">小于1A时</div>
              <div class="font">在</div>
              <el-input
                class="ipt"
                placeholder="请输入"
                v-model="item.duration"
                maxlength="4"
              />

              <div class="font">min内</div>
              <el-select
                class="ipt"
                v-model="item.check_channel_index"
                placeholder="水位"
              >
                <el-option
                  v-for="item in level"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <div class="font">下降</div>
              <el-input
                class="ipt"
                placeholder="请输入"
                v-model="item.max_value"
                maxlength="3"
              />
              <div class="font">%</div>
            </div>
          </div>

          <div class="group" v-else-if="item.tpl === 10">
            <div class="tpl">
              <div class="font">当</div>
              <el-select class="ipt" v-model="item.channel_index" placeholder="电流">
                <el-option
                  v-for="item in pump_cycle"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <div class="font">在</div>
              <div class="time_rows">
                <div class="time_line" v-for="(it, id) in item.timeList" :key="id">
                  <el-time-picker
                    style="flex: 0 0 166px"
                    is-range
                    :key="id"
                    v-model="it.time"
                    range-separator="-"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    placeholder="选择时间范围"
                    format="HH:mm"
                    value-format="HH:mm"
                    :clearable="false"
                  >
                  </el-time-picker>
                  <img src="../../../assets/del1.png" alt="" @click="delTime(item, id)" />
                </div>
              </div>

              <div class="font">大于1A</div>
            </div>

            <div class="add_time" v-if="item.timeList.length < 3">
              <div class="add" @click="addTime(item)">
                <img src="../../../assets/add2.png" alt="" />
                <span>添加时段</span>
              </div>
            </div>
          </div>

          <div class="group" v-else-if="item.tpl === 11">
            <div class="tpl">
              <div class="font">当</div>
              <el-select class="ipt" v-model="item.channel_index" placeholder="电流">
                <el-option
                  v-for="item in pump_cycle"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <div class="font">在</div>
              <div class="time_rows">
                <div class="time_line" v-for="(it, id) in item.timeList" :key="id">
                  <el-time-picker
                    style="flex: 0 0 166px"
                    is-range
                    v-model="it.time"
                    range-separator="-"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    placeholder="选择时间范围"
                    format="HH:mm"
                    value-format="HH:mm"
                    :clearable="false"
                  >
                  </el-time-picker>
                  <img src="../../../assets/del1.png" alt="" @click="delTime(item, id)" />
                </div>
              </div>

              <div class="font">小于1A</div>
            </div>

            <div class="add_time" v-if="item.timeList.length < 3">
              <div class="add" @click="addTime(item)">
                <img src="../../../assets/add2.png" alt="" />
                <span>添加时段</span>
              </div>
            </div>
          </div>

          <div class="group" v-else-if="item.tpl === 12">
            <div class="tpl">
              <div class="font">当</div>
              <el-select class="ipt" v-model="item.channel_index" placeholder="电流">
                <el-option
                  v-for="item in pump_cycle"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <div class="font">持续</div>
              <el-input
                class="ipt"
                placeholder="请输入"
                v-model="item.duration"
                maxlength="4"
              />
              <div class="font">min</div>
              <div class="font">大于1A</div>
            </div>
          </div>

          <div class="group" v-else-if="item.tpl === 20">
            <div class="tpl">
              <div class="font">当</div>
              <el-select class="ipt" v-model="item.channel_index" placeholder="电流">
                <el-option
                  v-for="item in mach"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <div class="font">连续</div>
              <el-input
                class="ipt"
                placeholder="请输入"
                v-model="item.duration"
                maxlength="4"
              />
              <div class="font">min</div>
              <div class="font">大于1A</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row4" v-if="!isFormNotEmpty && device_type !== ''">暂未添加模板</div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="validate">保 存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { GetWarnChannelApi, AddWarnApi, GetWarnTypeApi } from "@/api";
import formData from "./form_data.js";

export default {
  data() {
    return {
      // 是否显示弹窗
      dialogVisible: false,
      // 预警设备id
      id: "",
      // 动态渲染表单
      /**
       * 0 水箱 1水泵 2机组
       * 0 水箱水位 1水箱水温 2水箱供水泵水温 3水箱供水泵水位     10：水泵电流大于 11：水泵电流小于 12：水泵开启   20：机组电流开启
       */
      formList: [],
      warn_name: "",
      device_type: "",
      // 水位
      level: [],
      // 水温
      temp: [],
      // 机组
      mach: [],
      // 供水
      pump: [],
      // 供水+循环
      pump_cycle: [],
    };
  },
  methods: {
    async add(id) {
      this.id = id;
      const params = { id };
      const { ret, data, msg } = await GetWarnTypeApi(params);
      if (ret !== 0) {
        return this.$message.error(msg);
      }
      const formList = [];
      formData.map((i) => {
        if (data.includes(i.tpl)) {
          const temp = {
            ...i,
          };
          if ("timeList" in temp) {
            temp.timeList = [
              {
                time: ["00:00", "23:59"],
              },
            ];
          }
          formList.push(temp);
        }
      });
      this.formList = formList;
      this.dialogVisible = true;
      this.getChannel(id);
    },

    // 去重 排序
    uniqueAndSort(...args) {
      const temp = [];
      args.forEach((item) => {
        temp.push(...item);
      });
      return [...new Set(temp)].sort((a, b) => a - b);
    },

    // 获取可用通道
    async getChannel(id) {
      const params = { id };
      const { ret, data, msg } = await GetWarnChannelApi(params);
      if (ret !== 0) {
        return this.$message.error(msg);
      }
      const level = [];
      const temp = [];
      const mach = [];
      const pump = [];
      const pumpCycle = [];
      const levelList = this.uniqueAndSort(data.level);
      levelList.map((i) => {
        level.push({
          value: i,
          label: "P" + (i + 1),
        });
      });
      const tempList = this.uniqueAndSort(data.temp);
      tempList.map((i) => {
        temp.push({
          value: i,
          label: "T" + (i + 1),
        });
      });
      const machList = this.uniqueAndSort(data.matchine_e);
      machList.map((i) => {
        mach.push({
          value: i,
          label: "E" + (i + 1),
        });
      });
      const pumpList = this.uniqueAndSort(data.pump_0_e);
      pumpList.map((i) => {
        pump.push({
          value: i,
          label: "P" + (i + 1),
        });
      });
      const pumpCycleList = this.uniqueAndSort(data.pump_0_e, data.pump_1_e);
      pumpCycleList.map((i) => {
        pumpCycle.push({
          value: i,
          label: "E" + (i + 1),
        });
      });

      this.level = level;
      this.temp = temp;
      this.mach = mach;
      this.pump = pump;
      this.pump_cycle = pumpCycle;
    },

    // 清除数据
    clearForm() {
      this.warn_name = "";
      this.device_type = "";
      this.formList = [];
      console.log(this.formList, "formList");
    },

    // 删除时间
    delTime(row, index) {
      if (row.timeList.length === 1) {
        return this.$message.warning("至少保留一个时段");
      }
      row.timeList.splice(index, 1);
    },

    // 添加时段
    addTime(row) {
      console.log(this.filterFormList, "filterFormList");
      row.timeList.push({
        time: ["00:00", "23:59"],
      });
      console.log(this.filterFormList, "filterFormList2");
    },

    // 校验数据
    checkData(arr) {
      let msg = "";
      const durationReg = /^[1-9][0-9]{0,3}$/;
      const minMaxReg = /(^[1-9][0-9]?$)|(^100$)/;
      for (let i = 0; i < arr.length; i++) {
        const obj = arr[i];
        if ("channel_index" in obj) {
          if (obj.channel_index === "") {
            msg = `请检查${obj.name}下拉框选择`;
            break;
          }
        }
        if ("duration" in obj) {
          if (!durationReg.test(obj.duration)) {
            msg = `请检查${obj.name}持续时间`;
            break;
          }
        }
        if ("min_value" in obj) {
          if (!minMaxReg.test(obj.min_value)) {
            msg = `请检查${obj.name}数值输入`;
            break;
          }
        }
        if ("max_value" in obj) {
          if (!minMaxReg.test(obj.max_value)) {
            msg = `请检查${obj.name}数值输入`;
            break;
          }
        }
        if ("check_channel_index" in obj) {
          if (obj.check_channel_index === "") {
            msg = `请检查${obj.name}下拉框选择`;
            break;
          }
        }
        if (obj.tpl === 0 || obj.tpl === 1) {
          if (obj.min_value >= obj.max_value) {
            msg = `请检查${obj.name}数值区间`;
            break;
          }
        }
      }
      return msg;
    },

    // 校验用户输入
    validate() {
      if (!this.warn_name.trim()) {
        return this.$message.warning("请输入预警名称");
      }
      if (this.device_type === "") {
        return this.$message.warning("请选择预警类型");
      }
      const formList = this.filterFormList.filter((i) => i.checked);
      if (!formList.length) {
        return this.$message.warning("请选择预警模板");
      }
      const errMsg = this.checkData(formList);
      if (errMsg) {
        return this.$message.warning(errMsg);
      }
      this.save();
    },

    // 保存预警设置
    async save() {
      const params = {
        id: this.id,
        warn_name: this.warn_name.trim(),
        device_type: this.device_type,
        desc_array: [],
      };
      const keys = [
        "channel_index",
        "duration",
        "check_channel_index",
        "min_value",
        "max_value",
      ];
      this.filterFormList
        .filter((i) => i.checked)
        .map((j) => {
          const te = {
            warn_type: j.tpl,
            time_array: [],
          };
          keys.forEach((k) => {
            if (k in j) {
              te[k] = Number(j[k]);
            }
          });
          if ("timeList" in j) {
            j.timeList.map((timeObj) => {
              te.time_array.push({
                time_start: timeObj.time[0] + ":00",
                time_end: timeObj.time[1] + ":59",
              });
            });
          }
          params.desc_array.push(te);
        });
      const { ret, data, msg } = await AddWarnApi(params);
      if (ret !== 0) {
        return this.$message.error(msg);
      }
      this.$message.success("添加成功");
      this.$emit("refresh");
      this.dialogVisible = false;
    },
  },
  computed: {
    filterFormList() {
      return this.formList.filter((item) => item.device_type === this.device_type);
    },
    isFormNotEmpty() {
      return !this.filterFormList.every((item) => !item.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
.row1 {
  display: flex;

  .item {
    flex: 0 0 40%;
    display: flex;
    align-items: center;

    .desc {
      height: 32px;
      line-height: 32px;
      flex: 0 0 100px;
      text-align: right;
      font-family: Source Han Sans, Source Han Sans;
      font-weight: 400;
      font-size: 14px;
      position: relative;

      &::after {
        content: "*";
        position: absolute;
        left: 20px;
        top: 0px;
        color: #fa5151;
      }
    }
  }
}

.row2 {
  display: flex;
  height: 32px;
  margin-top: 16px;

  .desc {
    line-height: 32px;
    flex: 0 0 100px;
    text-align: right;
    font-family: Source Han Sans, Source Han Sans;
    font-size: 14px;
  }

  .item_box {
    display: flex;

    .item {
      height: 32px;
      line-height: 32px;
      padding: 0 16px;
      background: #f6fafd;
      color: #3d3d3d;
      border-radius: 4px;
      font-family: Source Han Sans, Source Han Sans;
      font-weight: 400;
      font-size: 14px;
      margin-right: 12px;
      cursor: pointer;
    }

    .checked {
      @extend .item;
      background-color: #2a47b0;
      color: #ffffff;
    }
  }
}

.row3 {
  padding-left: 100px;
  margin-top: 8px;

  .group {
    &:hover {
      background: #f6fafd;
    }

    .tpl {
      display: flex;
      padding: 8px;

      .font {
        font-family: Source Han Sans, Source Han Sans;
        font-weight: 400;
        font-size: 14px;
        color: #3d3d3d;
        line-height: 32px;
        margin-right: 16px;
      }

      .ipt {
        flex: 0 0 90px;
        margin-right: 16px;
      }

      .time_rows {
        flex: 0 0 200px;
        min-width: 0;
        margin-right: 16px;
        overflow: hidden;

        .time_line {
          height: 32px;
          display: flex;
          align-items: center;

          &:nth-child(n + 2) {
            margin-top: 16px;
          }

          img {
            width: 16px;
            height: 16px;
            flex: 0 0 16px;
            margin-left: 16px;
            cursor: pointer;
          }
        }
      }
    }

    .add_time {
      display: flex;
      align-items: center;
      padding: 8px 0 8px 176px;

      .add {
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          width: 11px;
          height: 11px;
        }

        span {
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #2b47ac;
          margin-left: 4px;
        }
      }
    }
  }
}

.row4 {
  height: 32px;
  padding: 16px;
  text-align: center;
  line-height: 32px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 14px;
  color: #c0c4cc;
}
</style>
