<template>
  <el-dialog
    title="编辑预警接收人"
    :visible.sync="dialogVisible"
    width="800px"
    @closed="contactList = []"
  >
    <div class="row" v-for="(item, index) in contactList" :key="index">
      <span class="desc">接收人{{ index + 1 }}：</span>
      <el-input placeholder="请输入姓名" class="ipt" v-model="item.name" maxlength="4" />
      <span class="split">-</span>
      <el-input
        placeholder="请输入联系方式"
        class="ipt"
        v-model="item.tel"
        maxlength="11"
      />
      <img src="../../../assets/del1.png" alt="" class="del" @click="delRow(index)" />
    </div>

    <div class="add_row" v-if="contactList.length < 5">
      <div class="add" @click="addRow">
        <img src="../../../assets/add2.png" alt="" />
        <span>添加接收人</span>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="save">保 存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { AddWarnTelApi } from "@/api";

export default {
  data() {
    return {
      // 是否显示弹窗
      dialogVisible: false,
      // 项目id
      idProject: "",
      // 联系人
      contactList: [],
    };
  },
  methods: {
    open(idProject, list) {
      list.map((i) => {
        this.contactList.push({ ...i });
      });
      this.idProject = idProject;
      this.dialogVisible = true;
    },

    addRow() {
      this.contactList.push({ name: "", tel: "" });
    },

    delRow(index) {
      this.contactList.splice(index, 1);
    },

    // 点击保存按钮
    async save() {
      let tip = "";
      // 格式校验
      for (let i = 0; i < this.contactList.length; i++) {
        const name = this.contactList[i].name;
        const tel = this.contactList[i].tel;
        if (!name) {
          tip = "请输入姓名";
          break;
        }
        if (!tel) {
          tip = "请输入联系方式";
          break;
        }
        if (!/^1\d{10}$/.test(tel)) {
          tip = "请输入正确的联系方式";
          break;
        }
      }
      // 校验手机号不能重复
      const telArr = this.contactList.map((item) => item.tel);
      if (new Set(telArr).size !== telArr.length) {
        tip = "手机号不能重复";
      }

      if (tip) {
        this.$message.warning(tip);
        return;
      }

      const params = {
        id_project: this.idProject,
        tel_array: this.contactList,
      };
      const { ret, data, msg } = await AddWarnTelApi(params);
      if (ret !== 0) {
        return this.$message.error(msg);
      }
      this.$message.success("保存成功");
      this.$emit("refresh");
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  align-items: center;
  padding: 8px 0;

  .desc {
    flex: 0 0 100px;
    text-align: right;
    padding-right: 12px;
    position: relative;

    &::before {
      content: "*";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 14px;
      z-index: 1;
      color: #fa5151;
    }
  }

  .ipt {
    flex: 0 0 218px;
  }

  .split {
    margin: 0 12px;
  }

  .del {
    flex: 0 0 11px;
    width: 11px;
    height: 11px;
    margin-left: 16px;
    cursor: pointer;
  }
}

.add_row {
  padding: 12px 0 12px 100px;

  .add {
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
      width: 12px;
      height: 12px;
    }

    span {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #2b47ac;
      margin-left: 4px;
    }
  }
}
</style>
