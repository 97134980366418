<template>
  <div class="container">
    <!-- 默认显示 -->
    <div class="header">
      <div class="left_box">
        <span>选择项目</span>
        <el-cascader
          v-model="selected"
          :options="options"
          :props="{
            expandTrigger: 'hover',
            label: 'name',
            value: 'id',
          }"
          @change="getSysList"
          :show-all-levels="false"
        ></el-cascader>
      </div>

      <div class="right_box" v-if="selected.length">
        <div class="users" v-if="contactList.length" @click="showChangeContact">
          <div class="name">{{ contactList.map((i) => i.name).join("、") }}</div>
        </div>
        <div class="add" v-else @click="showChangeContact">
          <img src="../../assets/add2.png" alt="" />
          <span>添加预警接收人</span>
        </div>
      </div>
    </div>

    <!-- 楼栋列表 -->
    <div
      class="grid_view"
      v-if="sysList.length"
      :class="sysList.length < 3 ? 'only_row' : ''"
    >
      <div
        class="item"
        v-for="item in sysList"
        :key="item.id"
        :class="item.warn_list.length ? 'open' : ''"
      >
        <div class="head_box">
          <div class="left_box">
            <div
              class="switch"
              v-if="item.warn_list.length"
              @click="changeSwitch(item)"
              :class="item.is_warn_notify ? 'open' : ''"
            >
              <div class="circle"></div>
            </div>

            <div class="name">{{ item.name }}</div>
          </div>
          <div class="right_box" v-if="item.warn_list.length" @click="showAddWarn(item)">
            <img src="../../assets/add2.png" alt="" />
            <span>预警规则</span>
          </div>
        </div>
        <el-scrollbar class="content" v-if="item.warn_list.length">
          <div class="row" v-for="it in item.warn_list" :key="it.id">
            <el-popover
              placement="right"
              trigger="click"
              @show="getWarnDetail(it)"
              @hide="warnContent = []"
            >
              <div v-for="(warn, index) in warnContent" :key="index">{{ warn }}</div>
              <div class="remark" slot="reference">{{ it.warn_name }}</div>
            </el-popover>

            <div class="del">
              <img src="../../assets/del2.png" @click="delWarn(it)" alt="" />
            </div>
          </div>
        </el-scrollbar>
        <div class="no_content" v-else>
          <div class="add" @click="showAddWarn(item)">
            <img src="../../assets/add2.png" alt="" />
            <span>预警规则</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 空 -->
    <div class="empty" v-else>
      <img src="../../assets/no_sys.png" alt="" />
    </div>

    <!-- 编辑联系人弹窗 -->
    <ChangeContact ref="changeContactRef" @refresh="getSysList" />
    <!-- 添加预警规则弹窗 -->
    <AddWarn ref="addWarnRef" @refresh="getSysList" />
  </div>
</template>

<script>
import {
  GetProjectListV2Api,
  GetSysWarnListApi,
  UpdateWarnDeviceApi,
  DelWarnApi,
  GetWarnContentApi,
} from "@/api";
import ChangeContact from "./cpts/change_contact.vue";
import AddWarn from "./cpts/add_warn.vue";

export default {
  name: "warn_setting",
  components: { ChangeContact, AddWarn },
  data() {
    return {
      // 选中的项目
      selected: [],
      // 项目树形结构
      options: [],
      // 系统列表
      sysList: [],
      // 预警联系人
      contactList: [],
      // 预警内容数组
      warnContent: [],
    };
  },
  methods: {
    // 获取项目列表
    async getProjectList() {
      const params = {};
      const { ret, data, msg } = await GetProjectListV2Api(params);
      if (ret !== 0) {
        return this.$message.error(msg);
      }
      const role = this.$store.state.user.role;
      const options = [];
      if (role === 100) {
        // 超级管理员
        data.map((i) => {
          if (i.project.length) {
            const temp = {
              id: i.id_operator,
              name: i.name_operator,
              children: [],
            };
            i.project.map((j) => {
              temp.children.push({
                id: j.id,
                name: j.name,
              });
            });
            options.push(temp);
          }
        });
        this.options = options;
      } else {
        const options = [];
        data.map((i) => {
          options.push({
            id: i.id,
            name: i.name,
          });
        });
        this.options = options;
      }
    },

    // 选中项目, 获取系统列表
    async getSysList() {
      const id = this.selected[this.selected.length - 1];
      const params = {
        id_project: id,
      };
      const { ret, data, msg } = await GetSysWarnListApi(params);
      if (ret !== 0) {
        return this.$message.error(msg);
      }
      this.sysList = data.deviec_list;
      this.contactList = data.warn_tel_list;
    },

    // 显示编辑联系人的弹窗
    showChangeContact() {
      this.$refs.changeContactRef.open(
        this.selected[this.selected.length - 1],
        this.contactList
      );
    },

    // 显示添加预警规则的弹窗
    showAddWarn(row) {
      console.log(row);
      this.$refs.addWarnRef.add(row.id);
    },

    // 切换switch
    async changeSwitch(row) {
      const result = await this.$confirm(
        `您确定要${row.is_warn_notify === 1 ? "关闭" : "开启"}当前预警吗`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch(() => "cancel");
      if (result === "cancel") return;
      console.log(result);
      const params = {
        id: row.id,
        is_warn_notify: 1 - row.is_warn_notify,
      };
      const { ret, data, msg } = await UpdateWarnDeviceApi(params);
      if (ret !== 0) {
        return this.$message.error(msg);
      }
      this.$message.success("操作成功");
      this.getSysList();
    },

    // 删除预警
    async delWarn(row) {
      const result = await this.$confirm(`您确定要删除当前预警规则吗`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch(() => "cancel");
      if (result === "cancel") return;
      console.log("12");
      const params = {
        id: row.id,
      };
      const { ret, data, msg } = await DelWarnApi(params);
      if (ret !== 0) {
        return this.$message.error(msg);
      }
      this.$message.success("删除成功");
      this.getSysList();
    },
    // 获取预警内容
    async getWarnDetail(row) {
      const params = {
        id: row.id,
      };
      const { ret, data, msg } = await GetWarnContentApi(params);
      if (ret !== 0) {
        return this.$message.error(msg);
      }
      this.warnContent = data;
    },
  },
  created() {
    this.getProjectList();
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 0 26px;
}

.header {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left_box {
    display: flex;
    align-items: center;

    span {
      font-size: 14px;
      font-family: Source Han Sans-Regular, Source Han Sans;
      font-weight: 400;
      color: #3d3d33;
      margin-right: 6px;
    }
  }

  .right_box {
    .add {
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        width: 11px;
        height: 11px;
        margin-right: 4px;
      }

      span {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #2b47ac;
      }
    }

    .users {
      cursor: pointer;

      .name {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #2b47ac;
      }
    }
  }
}

.grid_view {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  gap: 18px;

  &.only_row {
    grid-template-columns: repeat(auto-fit, minmax(330px, 400px));
  }

  .item {
    height: 220px;
    border-radius: 12px;
    overflow: hidden;
    background: linear-gradient(180deg, #eff2f7 0%, #ffffff 100%);
    box-shadow: 0px 4px 10px 0px rgba(42, 71, 176, 0.06);

    &.open {
      background: linear-gradient(180deg, #eff2f7 0%, #ffffff 100%);
      box-shadow: 0px 4px 10px 0px rgba(42, 71, 176, 0.06);

      .head_box {
        background: linear-gradient(
          270deg,
          rgba(42, 71, 176, 0.1) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        box-shadow: 0px 4px 10px 0px rgba(42, 71, 176, 0.06);
      }
    }

    .head_box {
      width: 100%;
      height: 72px;
      background: linear-gradient(-90deg, #dbdbdb 1%, rgba(255, 255, 255, 0) 97%);
      box-shadow: 0px 4px 10px 0px rgba(134, 134, 134, 0.06);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px;
      overflow: hidden;

      .left_box {
        flex: 1;
        display: flex;
        overflow: hidden;

        .switch {
          width: 34px;
          flex: 0 0 34px;
          height: 20px;
          background: #c0c4cc;
          border-radius: 15px 15px 15px 15px;
          margin-right: 12px;
          padding: 2px;
          display: flex;
          cursor: pointer;

          .circle {
            width: 16px;
            height: 16px;
            background: #ffffff;
            border-radius: 15px 15px 15px 15px;
          }

          &.open {
            justify-content: flex-end;
            background-color: #2a47b0;
          }
        }

        .name {
          font-family: Source Han Sans, Source Han Sans;
          font-weight: 500;
          font-size: 18px;
          color: #3d3d3d;
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .right_box {
        flex: 0 0 100px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        user-select: none;

        img {
          width: 11px;
          height: 11px;
          margin-right: 4px;
        }

        span {
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #2b47ac;
        }
      }
    }

    .content {
      height: 148px;
      width: 100%;
      padding: 7px 0;

      .row {
        padding: 12px 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .remark {
          font-family: Source Han Sans, Source Han Sans;
          font-weight: 400;
          font-size: 14px;
          color: #767676;

          &:hover {
            color: #2a47b0;
            cursor: pointer;
            user-select: none;
          }
        }

        .del {
          width: 20px;
          height: 20px;

          img {
            width: inherit;
            height: inherit;
            cursor: pointer;
          }
        }
      }
    }

    .no_content {
      height: 148px;
      cursor: pointer;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 11px;
        height: 11px;
        margin-right: 4px;
      }

      span {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #2b47ac;
      }
    }
  }
}

.empty {
  height: calc(100vh - 168px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
  background-color: #fff;
  user-select: none;

  img {
    width: 400px;
    flex: 0 0 400px;
    height: 336px;
  }
}
</style>
