export default [
  {
    device_type: 0,
    tpl: 0,
    name: "水位预警",
    checked: false,
    channel_index: "",
    duration: "",
    min_value: "",
    max_value: "",
  },
  {
    device_type: 0,
    tpl: 1,
    name: "水温预警",
    checked: false,
    channel_index: "",
    duration: "",
    min_value: "",
    max_value: "",
  },
  {
    device_type: 0,
    tpl: 2,
    name: "低温供水预警",
    checked: false,
    channel_index: "",
    check_channel_index: "",
    min_value: "",
  },
  {
    device_type: 0,
    tpl: 3,
    name: "漏水预警",
    checked: false,
    channel_index: "",
    duration: "",
    check_channel_index: "",
    max_value: "",
  },
  {
    device_type: 1,
    tpl: 10,
    name: "水泵未开预警",
    checked: false,
    channel_index: "",
    timeList: [
      {
        time: ["00:00", "23:59"],
      },
    ],
  },
  {
    device_type: 1,
    tpl: 11,
    name: "水泵未关预警",
    checked: false,
    channel_index: "",
    timeList: [
      {
        time: ["00:00", "23:59"],
      },
    ],
  },
  {
    device_type: 1,
    tpl: 12,
    name: "工作超时预警",
    checked: false,
    channel_index: "",
    duration: "",
  },
  {
    device_type: 2,
    tpl: 20,
    name: "工作超时预警",
    checked: false,
    channel_index: "",
    duration: "",
  },
]
